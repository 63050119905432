
import Hero from "@/components/elements/Hero.vue";
import Header from "@/components/elements/Header.vue";
import GuideTitle from "@/components/title/GuideTitle.vue";
import {Options, Vue} from "vue-class-component";
import Settings from "@/services/Settings";

@Options({
  components: {
    GuideTitle,
    Header,
    Hero
  },
})
export default class FansGuide extends Vue {
  private id: string | null = null;
  private host: string | null = null;
  private loader = false;
  private game: any = {};

  loadData(): void {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/public/games/' + this.id, {
      params: {}
    }).then((response) => {
      this.game = response.data;
    }, () => {
      this.loader = false;
    });
  }

  mounted(): void {
    this.id = this.$route.params.gameId as string;
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loadData();
  }
}

